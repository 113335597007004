<template>
    <div>
      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-briefcase"></i> Dados do Componente
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-form>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Descrição">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.description.$error }"
                    v-model="$v.payload.description.$model"
                    :state="$v.payload.description.$dirty ?
                    !$v.payload.description.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group label="Unidade de medida">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.unit.$error }"
                    v-model="$v.payload.unit.$model"
                    :state="$v.payload.unit.$dirty ?
                    !$v.payload.unit.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group label="Preço custo">
                  <money
                    class="form-control"
                    :class="{ 'form-group--error': $v.payload.price.$error }"
                    v-model="$v.payload.price.$model"
                    :state="$v.payload.price.$dirty ?
                    !$v.payload.price.$error : null"
                    v-bind="money"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

                <div class="w-100 text-left mt-3">
                    <b-button class="ml-3" variant="success" @click="save(false)">Salvar</b-button>
                    <b-button class="ml-3" variant="primary" v-if="!id" @click="save(true)">Salvar e continuar</b-button>
                </div>
            </b-row>
          </b-form>
        </div>
      </b-row>
    </div>
  </template>

<script>
import { required } from 'vuelidate/lib/validators';
import { Money } from 'v-money';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';
import ComponentsService from '../../../Services/ComponentsService';

export default {
  props: ['id'],
  components: {
    Money,
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
      payload: {
        description: '',
        unit: '',
        price: '',
      },
    };
  },
  validations: {
    payload: {
      description: { required },
      unit: { required },
      price: { required },
    },
  },
  methods: {
    async getComponent() {
      try {
        const { data } = await ComponentsService.get(this.id);
        this.payload = data;
      } catch (error) {
        this.error(error);
      }
    },
    resertForm() {
      this.payload = {
        description: '',
        unit: '',
        price: '',
      };
      this.$v.payload.$reset();
    },
    async save(continueSave) {
      this.$v.payload.$touch();
      if (!this.$v.payload.$invalid) {
        try {
          const { data } = await this.id ? ComponentsService.edit(this.payload) : ComponentsService.save(this.payload);
          console.log(data);

          if (this.id) {
            this.messageSuccess('Componente editado com sucesso!');
          } else {
            this.messageSuccess('Componente cadastrado com sucesso!');
          }

          if (continueSave && !this.id) {
            this.resertForm();
          } else {
            this.$router.push({ name: 'components' });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  created() {
    if (this.id) {
      this.getComponent();
    }
  },
};
</script>
